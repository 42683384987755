<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.payments.setup')" class="payments">
    <CCol cols="12" lg="12">
      <CCard class="mb-0 payments wizard with_space">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol class="pt-0 pb-0 text-left">
              <span>{{$t('payments.Set_financial_details')}}</span>
            </CCol>
            <CCol v-if="financialDetails.setup_completed === false && environmentDetails.environment_schedule_call_url" cols="6" xl="6" class="pt-0 pb-0 text-right">
              <CButton color="secondary" @click="openContactCallSchedulePage()" class="m-0">
                <span><i class="fa-solid fa-calendar-days mr-1"></i>{{$t('common.Schedule_contact_call')}}</span>
              </CButton>              
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody :class="{'p-0' : financialDetails.setup_completed === true}">
          <div v-if="financialDetails.setup_completed === false"> 
            <CRow>          
              <CCol cols="12" lg="12" class="p-0">
                <b-steps v-model="activeWizardStep"
                        size="is-small"
                        class="wizard_steps h-100"
                        @input="nextWizardStep()"
                        :animated="isAnimated"
                        :rounded="isRounded"
                        :has-navigation="hasNavigation"
                        :icon-prev="prevIcon"
                        :icon-next="nextIcon"
                        :label-position="labelPosition"
                        :mobile-mode="mobileMode">
                                      
                  <b-step-item :label="$t('payments.Payment_method')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                    <CRow class="w-100 m-0">
                      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                        <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                          <div class="text-center">
                            <h2 class="step_title large mt-0 mb-1">{{$t('payments.Which_method_to_use')}}</h2>                            
                            <span class="step_notice">{{$t('payments.Methods_explainer', { environment_name : companyDetails.details.environment_name })}}</span>
                          </div>
                          <div class="d-flex align-items-center justify-content-center step_option_cards">
                            <CCard v-for="type in paymentTypes" 
                                   :key="type.payment_type_id_external" 
                                   class="pointer show_border" 
                                   :class="{'selected' : financialDetails.payment_type_tag === type.payment_type_tag}" 
                                   @click="setPaymentType(type)">
                              <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid mr-2" :class="type.icon_class"></i>
                                  <span>{{$t('payments.Payment_method_' + type.payment_type_tag)}}</span>
                              </CCardBody>
                            </CCard>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </b-step-item>

                  <b-step-item :label="$t('payments.Invoice_details')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                    <CRow class="m-0">
                      <CCol cols="12" lg="12">
                        <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                          <h2 class="step_title large m-0">{{$t('payments.Set_your_invoice_details')}}</h2>
                          <div class="w-100 pb-0 wizard_step_form">
                            <CRow class="w-100 m-0">
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('core.company_name') + ' *'" type="text" class="mb-0" v-model="financialDetails.invoice_company_name" v-debounce:0.5s="validateInvoiceDetails" required was-validated/>
                              </CCol>
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.Email_address') + ' *'" type="email" class="mb-0" v-model="financialDetails.invoice_company_email" v-debounce:0.5s="validateInvoiceDetails" required was-validated/>
                              </CCol>                              
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="8" xl="8" lg="8" md="12" sm="12">
                                <CInput :label="$t('common.Address') + ' *'" type="text" class="mb-0" v-model="financialDetails.invoice_company_address" v-debounce:0.5s="validateInvoiceDetails" required was-validated/>
                              </CCol>
                              <CCol cols="4" xl="4" lg="4" md="12" sm="12">
                                <CInput :label="$t('common.Zip_code') + ' *'" type="text" class="mb-0" v-model="financialDetails.invoice_company_zip_code" v-debounce:0.5s="validateInvoiceDetails" required was-validated/>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.Place') + ' *'" type="text" class="mb-0" v-model="financialDetails.invoice_company_place" v-debounce:0.5s="validateInvoiceDetails" required was-validated/>
                              </CCol>
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <label>{{$t('common.Country') + ' *'}}</label>
                                <multiselect class="open_absolute"
                                  v-model="financialDetails.invoice_company_country" 
                                  :options="countries" 
                                  :multiple="false"
                                  :hideSelected="false"
                                  :closeOnSelect="true"
                                  track-by="code" 
                                  label="name"
                                  :show-labels="false"
                                  @input="validateInvoiceDetails">
                                  <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                                  <template slot="singleLabel" slot-scope="country">
                                    <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                                    <span class="language_name">{{ country.option.name }}</span>
                                  </template>

                                  <template slot="option" slot-scope="country">
                                    <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                                    <span class="language_name">{{ country.option.name }}</span>
                                  </template>
                                </multiselect>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.Coc_number')" type="text" class="mb-0" v-model="financialDetails.invoice_company_coc_number"/>
                              </CCol>
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.VAT_number')" type="text" class="mb-0" v-model="financialDetails.invoice_company_vat_number"/>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                                <CTextarea :label="$t('payments.PO_number_reference')" rows="3" class="mb-0" v-model="financialDetails.invoice_company_po_number_reference"/>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <span>* {{$t('common.Required_field')}}</span>
                              </CCol>
                            </CRow>                                                      
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </b-step-item>

                  <b-step-item :label="$t('payments.Financial_contact')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                    <CRow class="m-0">
                      <CCol cols="12" lg="12">
                        <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                          <h2 class="step_title large m-0">{{$t('payments.Set_your_financial_contact')}}</h2>
                          <div class="w-100 pb-0 wizard_step_form">
                            <CRow class="w-100 m-0">
                              <CCol cols="3" xl="3" lg="3" md="12" sm="12">
                                <CInput :label="$t('common.First_name') + ' *'" type="text" class="mb-0" v-model="financialDetails.financial_contact_first_name" v-debounce:0.5s="validateFinancialContact" required was-validated/>
                              </CCol>
                              <CCol cols="3" xl="3" lg="3" md="12" sm="12">
                                <CInput :label="$t('common.Last_name') + ' *'" type="text" class="mb-0" v-model="financialDetails.financial_contact_last_name" v-debounce:0.5s="validateFinancialContact" required was-validated/>
                              </CCol>
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.Email_address') + ' *'" type="email" class="mb-0" v-model="financialDetails.financial_contact_email" v-debounce:0.5s="validateFinancialContact" required was-validated/>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12">
                                <CInput :label="$t('common.Function')" type="text" class="mb-0" v-model="financialDetails.financial_contact_function"/>
                              </CCol>                              
                              <CCol cols="6" xl="6" lg="6" md="12" sm="12" class="pb-0">
                                <CInput :label="$t('common.Phone_number')" type="text" class="mb-0" v-model="financialDetails.financial_contact_phone"/>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                <span>* {{$t('common.Required_field')}}</span>
                              </CCol>
                            </CRow>                               
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </b-step-item>                  

                  <b-step-item :label="$t('common.Summary')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                    <CRow class="m-0">
                      <CCol cols="12" lg="12">
                        <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                          <h2 class="step_title large m-0">{{$t('common.Summary')}}</h2>                          
                          <div class="w-100">
                            <CRow class="w-100 m-0">
                              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                                <CCard class="h-100 summary_card payment_method show_border mb-0">
                                  <CCardHeader>
                                    <div class="d-flex align-items-center justify-content-center">
                                      <div class="d-flex align-items-center justify-content-center header_icon">
                                        <i class="fa-solid fa-receipt"></i>
                                      </div>
                                      <h3 class="m-0 ml-2">{{$t('payments.Payment_method')}}</h3>
                                    </div>
                                  </CCardHeader>
                                  <CCardBody class="d-flex flex-column">
                                    <div class="h-100 d-flex flex-column">
                                      <label class="m-0">{{$t('payments.Selected_payment_method')}}</label>
                                      <div class="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                                        <i class="fa-solid mb-2" :class="financialDetails.payment_type_icon_class"></i>                                      
                                        <span>{{$t('payments.Payment_method_' + financialDetails.payment_type_tag)}}</span>
                                      </div>
                                    </div>
                                  </CCardBody>
                                </CCard>
                              </CCol>
                              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                                <CCard class="h-100 summary_card show_border mb-0">
                                  <CCardHeader>
                                    <div class="d-flex align-items-center justify-content-center">
                                      <div class="d-flex align-items-center justify-content-center header_icon">
                                        <i class="fa-solid fa-file-invoice"></i>
                                      </div>
                                      <h3 class="m-0 ml-2">{{$t('payments.Invoice_details')}}</h3>
                                    </div>
                                  </CCardHeader>
                                  <CCardBody class="d-flex flex-column">
                                    <div class="d-flex flex-column mb-2">
                                      <label class="m-0">{{$t('common.Company_address')}}</label>
                                      <span>{{financialDetails.invoice_company_address}}</span>
                                      <span>{{financialDetails.invoice_company_zip_code}}</span>
                                      <span>{{financialDetails.invoice_company_place}}</span>
                                      <span>{{financialDetails.invoice_company_country_name}}</span>
                                    </div>
                                    <div class="d-flex flex-column mb-2">
                                      <label class="m-0">{{$t('common.Coc_number')}}</label>
                                      <span>{{financialDetails.invoice_company_coc_number}}</span>
                                    </div>
                                    <div class="d-flex flex-column" :class="{'mb-2' : financialDetails.invoice_company_po_number_reference}">
                                      <label class="m-0">{{$t('common.VAT_number')}}</label>
                                      <span>{{financialDetails.invoice_company_vat_number}}</span>
                                    </div>
                                    <div v-if="financialDetails.invoice_company_po_number_reference" class="d-flex flex-column">
                                      <label class="m-0">{{$t('payments.PO_number_reference')}}</label>
                                      <span>{{financialDetails.invoice_company_po_number_reference}}</span>
                                    </div>
                                  </CCardBody>
                                </CCard>
                              </CCol>
                              <CCol cols="4" xl="4" lg="4" md="12" sm="12" class="pb-0">
                                <CCard class="h-100 summary_card show_border mb-0">
                                  <CCardHeader>
                                    <div class="d-flex align-items-center justify-content-center">
                                      <div class="d-flex align-items-center justify-content-center header_icon">
                                        <i class="fa-solid fa-user"></i>
                                      </div>
                                      <h3 class="m-0 ml-2">{{$t('payments.Financial_contact')}}</h3>
                                    </div>
                                  </CCardHeader>
                                  <CCardBody class="d-flex flex-column">
                                    <div class="d-flex flex-column mb-2">
                                      <label class="m-0">{{$t('common.Name')}}</label>
                                      <span>{{financialDetails.financial_contact_first_name}} {{financialDetails.financial_contact_last_name}}</span>
                                    </div>
                                    <div class="d-flex flex-column" :class="{'mb-2' : financialDetails.financial_contact_function}">
                                      <label class="m-0">{{$t('common.Email_address')}}</label>
                                      <span>{{financialDetails.financial_contact_email}}</span>
                                    </div>
                                    <div v-if="financialDetails.financial_contact_function" class="d-flex flex-column" :class="{'mb-2' : financialDetails.financial_contact_phone}">
                                      <label class="m-0">{{$t('common.Function')}}</label>
                                      <span>{{financialDetails.financial_contact_function}}</span>
                                    </div>
                                    <div v-if="financialDetails.financial_contact_phone" class="d-flex flex-column">
                                      <label class="m-0">{{$t('common.Phone_number')}}</label>
                                      <span>{{financialDetails.financial_contact_phone}}</span>
                                    </div>
                                  </CCardBody>
                                </CCard>
                              </CCol>
                            </CRow>
                            <CRow class="w-100 m-0">
                              <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                                <div class="mb-2">
                                  <span v-if="financialDetails.payment_type_tag === 'prepaid'">{{$t('payments.Prepaid_terms')}}</span>
                                  <span v-else-if="financialDetails.payment_type_tag === 'recurring'">{{$t('payments.Recurring_payments_terms')}}</span>
                                </div>
                                <b-checkbox v-model="agreedWithTerms">
                                  <span>{{$t('payments.I_agree_with_the_terms_above')}}</span>
                                </b-checkbox>
                              </CCol>
                            </CRow>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow v-if="errorMessage" class="m-0">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <div class="text-center">
                          <span class="error">{{errorMessage}}</span>
                        </div>
                      </CCol>
                    </CRow>
                  </b-step-item>             

                  <template v-if="customNavigation" slot="navigation" slot-scope="{previous, next}">
                    <CRow class="w-100 m-0">
                      <CCol cols="6" lg="6" class="pt-0 pb-0 text-left">
                        <CButton color="secondary" class="m-0" :disabled="previous.disabled" @click.prevent="previous.action">
                          <span><i class="fa-solid fa-chevron-left mr-1"/>{{$t('common.Previous')}}</span>
                        </CButton>
                      </CCol>
                      <CCol cols="6" lg="6" class="pt-0 pb-0 text-right">
                        <!-- Payment Method -->
                        <CButton v-if="activeWizardStep === 0" 
                                 color="secondary" 
                                 class="m-0" 
                                 @click="!financialDetailsIdExternal ? setCompanyFinancialDetails() : updateCompanyFinancialDetails();"
                                 @click.prevent="next.action"
                                 :disabled="!financialDetails.payment_type_tag">
                          <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                        </CButton>
                        <!-- Invoice Details -->
                        <CButton v-if="activeWizardStep === 1" 
                                 color="secondary"
                                 class="m-0"
                                 @click="updateCompanyFinancialDetails();"
                                 @click.prevent="next.action"
                                 :disabled="invoiceDetailsComplete === false">
                          <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                        </CButton>
                        <!-- Financial Contact -->
                        <CButton v-if="activeWizardStep === 2" 
                                 color="secondary" 
                                 class="m-0" 
                                 @click="updateCompanyFinancialDetails();"
                                 @click.prevent="next.action"
                                 :disabled="financialDetailsComplete === false">
                          <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                        </CButton>
                        <!-- Summary & Complete Setup -->
                        <CButton v-if="activeWizardStep === 3" 
                                 color="primary" 
                                 class="m-0"
                                 @click="createWeFactDebtor();"
                                 :disabled="agreedWithTerms === false">
                          <span><i class="fa-solid fa-check mr-1"/>{{$t('payments.Complete_setup')}}</span>
                        </CButton>                        
                      </CCol>
                    </CRow>
                  </template>
                </b-steps>
              </CCol>
            </CRow>
          </div>
          <div v-else>
            <CRow class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
                <div v-html="$t('payments.Financial_details_already_setup')"></div>
              </CCol>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                <CButton color="primary" @click="openDashboardHomePage()">
                  <span><i class="fas fa-house mr-2"/>{{$t('common.Back_to_home')}}</span>
                </CButton>                
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';
import Multiselect from 'vue-multiselect'

export default {
  name: 'PaymentsSetup',
  components: {
    noPermission,
    Multiselect
  }, 
  data() {
    return {
      environmentTag: null,
      environmentDetails: {
        environment_schedule_call_url: null
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      companyDetails: {},
      financialDetailsIdExternal: null,
      financialDetails: {
        invoice: {},
        financial_contact: {},
        setup_completed: false
      },
      invoiceDetailsComplete: false,
      financialContactComplete: false,
      agreedWithTerms: false,
      purchaseLoyaltyPoints: false,
      loyaltyPointsAmount: 0,
      paymentTypes: [],
      countries: [],
      errorMessage: null,
      activeWizardStep: 0,
      isAnimated: false,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist'
    }
  },
  methods: {
    nextWizardStep() {
      if(this.activeWizardStep === 1) {
        // Set the invoice_company_name value
        if(!this.financialDetails.invoice_company_name) this.financialDetails.invoice_company_name = this.companyDetails.details.company_name;
        // Set the invoice_country value
        if(!this.financialDetails.invoice_company_country) {
          this.financialDetails.invoice_company_country = {
            name: this.companyDetails.details.company_country_name,
            code: this.companyDetails.details.company_country_code,
            emoji: this.companyDetails.details.company_country_flag
          };
        }
        // Validate the invoice details
        this.validateInvoiceDetails();        
      } else if(this.activeWizardStep === 2) { 
        // Validate the financial contact
        this.validateFinancialContact();
      }
    }, 
    getCompanyFinancialDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails')
      .then(res => {      
        this.financialDetails = res.data.data;
        // Set the financialDetailsIdExternal value if data is available
        if(this.financialDetails.company_financial_details_id_external) this.financialDetailsIdExternal = this.financialDetails.company_financial_details_id_external;
      })
      .catch(err => {
        console.error(err);
      });      
    },
    setCompanyFinancialDetails() {
      // Set the params
      let params = {};
      params.financialDetailsData = this.financialDetails;
      // Save the financial details of the company
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails', params)
      .then(res => {
        // Set the financialDetailsIdExternal value      
        this.financialDetailsIdExternal = res.data.data.company_financial_details_id_external;
      })
      .catch(err => {
        console.error(err);
      });
    },
    updateCompanyFinancialDetails() {
      // Set the params
      let params = {};
      params.financialDetailsData = this.financialDetails;
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails/' + this.financialDetailsIdExternal, params)
      .catch(err => {
        console.error(err);
      });
    },
    validateInvoiceDetails() {
      let company_name = this.financialDetails.invoice_company_address;
      let email = this.financialDetails.invoice_company_zip_code;
      let address = this.financialDetails.invoice_company_address;
      let zip_code = this.financialDetails.invoice_company_zip_code;
      let place = this.financialDetails.invoice_company_place;
      let country = this.financialDetails.invoice_company_country;
      
      // Check if necessary data is available and update the invoiceDetailsComplete value
      if(!company_name || !email || !address || !zip_code || !place || !country) {
        this.invoiceDetailsComplete = false;
      } else {
        this.invoiceDetailsComplete = true;
      }
      // Force update      
      this.$forceUpdate();
    },
    validateFinancialContact() {
      let contact_first_name = this.financialDetails.financial_contact_first_name;
      let contact_last_name = this.financialDetails.financial_contact_last_name;
      let contact_email = this.financialDetails.financial_contact_email;
      
      // Check if necessary data is available and update the financialDetailsComplete value
      if(!contact_first_name || !contact_last_name || !contact_email) {
        this.financialDetailsComplete = false;
      } else {
        this.financialDetailsComplete = true;
      }
      // Force update      
      this.$forceUpdate();
    },
    createWeFactDebtor() {
      // Set the params
      let params = {};
      params.debtorData = this.financialDetails;      
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/wefact/debtor', params)
      .then(res => {
        // Create a new Mollie customer
        this.createMollieCustomer();
      })      
      .catch(err => {    
        if(err.response.data.code === 'Debtor Creation Failed') {          
          this.errorMessage = this.$t('payments.No_debtor_created');
        }
      });
    },
    createMollieCustomer() {
      // Set the params
      let params = {};
      params.customerData = this.financialDetails;       
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/customer', params)
      .then(res => {
        // Complete the setup
        this.completeCompanyFinancialDetailsSetup();
      })
      .catch(err => {    
        if(err.response.data.code === 'No Customer Created') {          
          this.errorMessage = this.$t('payments.No_customer_created');
        }
      });
    },
    completeCompanyFinancialDetailsSetup() {
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails/completesetup')
      .then(res => {      
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('payments.Financial_details_setup_succesful'), type: 'is-success', duration: 2000 });
        // Navigate to the dashboard homepage
        this.openDashboardHomePage();
      })       
      .catch(err => {    
        console.error(err);
      });
    },
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.companyDetails = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });
    },
    getPaymentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/types')
      .then(res => {      
        this.paymentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setPaymentType(type) {
      // Set the payment_type_tag and payment_type_icon_class values of the financialDetails
      this.financialDetails.payment_type_tag = type.payment_type_tag;
      this.financialDetails.payment_type_icon_class = type.icon_class;
      // Reset the agreedWithTerms value
      this.agreedWithTerms = false;      
      // Force update
      this.$forceUpdate();
    },
    getCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/countries')
      .then(res => {
        this.countries = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    openDashboardHomePage() {
      if(this.$router.currentRoute.path !== '/')  this.$router.push({path: '/'});
    },
    openContactCallSchedulePage() {
      window.open(this.environmentDetails.environment_schedule_call_url, '_blank');
    },
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((points/10));
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }   
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentDetails') !== null) this.environmentDetails = localStorage.getItem('environmentDetails');
  
    this.getPlatformPermissions();
    this.getCompanyDetails();
    this.getCompanyFinancialDetails();
    this.getPaymentTypes();
    this.getCountries();
  }
}
</script>